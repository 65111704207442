import React from 'react';
import StyledContainer from '../components/organisms/StyledContainer';
import ProductTypeCard from '../components/ProductTypeCard';
import ProductTypeHero from '../components/ProductTypeHero';
import ProductTypeLocation from '../components/ProductTypeLocation';
import { graphql, Link } from 'gatsby';

import ProductTypeSection from '../components/ProductTypeSection';
import SEO from '../components/SEO';

export default function ProductTypePage({data}){
    return(
        <>
            <SEO 
                title="Cannabis Edibles"
                description="For years, when most people thought about cannabis, images of rolling papers, weed pipes and cannabis buds 
                would only come to mind. In recent years, with the rise in varieties of cannabis products available, cannabis 
                edibles have become hugely popular among cannabis users of all walks of life. This could be because edibles 
                don't require you to actually smoke cannabis flower, which some people prefer to avoid if they don't enjoy the 
                smell or taste."
            />
            <ProductTypeHero
                title="Cannabis Edibles"
                subtitle="There's a Lot More to Cannabis Than Just Smoking Bowls"
                description={[
                    `For years, when most people thought about cannabis, images of rolling papers, weed pipes and cannabis buds 
                    would only come to mind. In recent years, with the rise in varieties of cannabis products available, cannabis 
                    edibles have become hugely popular among cannabis users of all walks of life. This could be because edibles 
                    don't require you to actually smoke cannabis flower, which some people prefer to avoid if they don't enjoy the 
                    smell or taste.`,

                    `So, if you've been interested in dipping your toes into the cannabis market and want to try something new, edibles 
                    might be the perfect choice to get you started.`
                ]}
                bannerImage={data.ediblesBackgroundImage}
            />
            <StyledContainer padding="120px">
                <ProductTypeSection products={data.edibles.nodes}/>
            </StyledContainer>
            <ProductTypeLocation/>
        </>
    );
}

export const query = graphql`
    query{
        edibles: allSanityProductType(filter: {productpage: {eq: "Edibles"}}) {
                nodes {
                    name
                    productpage
                    imagePlusAltHeader {
                        image {
                            asset {
                                gatsbyImageData(fit: FILLMAX, placeholder: BLURRED, height: 250)
                            }
                        }
                        alt
                    }
                    _rawContent
                    slug
                }
        }

        ediblesBackgroundImage: file(relativePath: { eq: "edibles-bg.png" }) {
            childImageSharp {
              gatsbyImageData(
                width: 1000
                quality: 90
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
              )
            }
        }
    }
`;